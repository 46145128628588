import { Card, Cell, StandardGrid, StyledHeading, View } from "@barscience/global-components";
import NoPaymentAccess from "./NoPaymentAccess";
import useStripeConnectState from "../../components/stripeConnect/useStripeConnectState";
import { useStripeConnect } from "../../components/stripeConnect/useStripeConnect";
import { ConnectComponentsProvider, ConnectNotificationBanner, ConnectPayments, ConnectPayouts } from "@stripe/react-connect-js";
import { useState } from "react";
import { NotificationCount } from "@stripe/connect-js";

export default function PaymentDashboard() {
  const connectInstance = useStripeConnect();
  const { state: connectState } = useStripeConnectState();
  const [hasNotifications, setHasNotifications] = useState<boolean>(false);

  const handleNotificationsChange = (response: NotificationCount) => {
    if (response.actionRequired > 0 || response.total > 0) {
      setHasNotifications(true);
    } else {
      setHasNotifications(false);
    }
  };

  if (!connectState.connectAccount?.stripeID) {
    return (
      <NoPaymentAccess />
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', '@media (max-width: 767px)': { alignItems: 'flex-start', flexDirection: 'column' } }}>
          <StyledHeading tag='h3'>Payment Dashboard</StyledHeading>
        </View>
      </Cell>
      <Cell lg={6} md={6} sm={4} style={hasNotifications ? {} : { display: 'none' }}>
        {connectInstance &&
          <ConnectComponentsProvider connectInstance={connectInstance}>
            <ConnectNotificationBanner
              collectionOptions={{
                fields: 'eventually_due',
                futureRequirements: 'include',
              }}
              onNotificationsChange={handleNotificationsChange}
            />
          </ConnectComponentsProvider>
        }
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card>
          {connectInstance &&
            <ConnectComponentsProvider connectInstance={connectInstance}>
              <ConnectPayouts />
            </ConnectComponentsProvider>
          }
        </Card>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <StyledHeading tag='h5' style={{ marginTop: '32px', marginBottom: '8px' }}>Payments</StyledHeading>
        <Card>
          {connectInstance &&
            <ConnectComponentsProvider connectInstance={connectInstance}>
              <ConnectPayments />
            </ConnectComponentsProvider>
          }
        </Card>
      </Cell>
    </StandardGrid >
  );
}