import { useEffect, useState } from "react";
import useStripeConnectState from "./useStripeConnectState";
import { gql, useMutation } from "@apollo/client";
import { loadConnectAndInitialize, StripeConnectInstance } from "@stripe/connect-js";
import { getStripePublicKey } from "../../util/stripe";

const CREATE_STRIPE_SESSION = gql`
mutation createStripeConnectSession {
  clientSecret: createStripeConnectSession
}
`;

type CreateStripeConnectSessionResponse = {
  clientSecret: string | null;
};

/**
 * Creates a connection to Stripe Connect that allows embedded Stripe components to access the user's Stripe account data.
 */
export const useStripeConnect = () => {
  const { state: connectState } = useStripeConnectState();
  const [stripeConnectInstance, setStripeConnectInstance] = useState<StripeConnectInstance | null>(null);
  const [createSession] = useMutation<CreateStripeConnectSessionResponse>(CREATE_STRIPE_SESSION);

  useEffect(() => {
    if (connectState.connectAccount?.stripeID) {
      const fetchClientSecret = async () => {
        const { data, errors } = await createSession();
        if (errors) {
          throw errors[0].message;
        }

        if (data?.clientSecret) {
          return data.clientSecret;
        }
        
        throw new Error("Failed to create Stripe Connect session");
      };

      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: getStripePublicKey(),
          fetchClientSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#199016",
              colorText: "#151615",
              colorBackground: "#FFFFFF",
              buttonPrimaryColorBackground: "#199016",
              buttonPrimaryColorBorder: "#199016",
              buttonPrimaryColorText: "#FFFFFF",
              buttonSecondaryColorBackground: "#FFFFFF",
              buttonSecondaryColorBorder: "#199016",
              buttonSecondaryColorText: "#199016",
              colorSecondaryText: "#676E67",
              actionPrimaryColorText: "#199016",
              actionPrimaryTextDecorationLine: "none",
              actionPrimaryTextDecorationColor: "#199016",
              actionPrimaryTextDecorationStyle: "solid",
              actionSecondaryColorText: "#0071D7",
              actionSecondaryTextDecorationColor: "#0071D7",
              colorBorder: "#D7DAD7",
              formHighlightColorBorder: "#199016",
              formAccentColor: "#199016",
              colorDanger: "#EF4444",
              badgeNeutralColorBackground: "#F2F3F2",
              badgeNeutralColorBorder: "#D7DAD7",
              badgeNeutralColorText: "#676E67",
              badgeSuccessColorBackground: "#F0FDF0",
              badgeSuccessColorBorder: "#A1DD88",
              badgeSuccessColorText: "#199016",
              badgeWarningColorBackground: "#FFFBF1",
              badgeWarningColorBorder: "#FFE2A2",
              badgeWarningColorText: "#EEA300",
              badgeDangerColorBackground: "#FEF2F2",
              badgeDangerColorBorder: "#FCA5A5",
              badgeDangerColorText: "#EF4444",
              offsetBackgroundColor: "#F2F3F2",
              formBackgroundColor: "#FFFFFF",
              borderRadius: "4px",
              buttonBorderRadius: "4px",
              formBorderRadius: "4px",
              badgeBorderRadius: "4px",
              overlayBorderRadius: "4px",
              overlayBackdropColor: "RGBA(103, 110, 103, 0.81)",
              fontFamily: "'Sharp Sans', 'Open Sans', sans-serif",
              fontSizeBase: "16px",
              headingXlFontSize: "28px",
              headingXlFontWeight: "700",
              headingLgFontSize: "24px",
              headingLgFontWeight: "700",
              headingMdFontSize: "20px",
              headingMdFontWeight: "700",
              headingSmFontSize: "16px",
              headingSmFontWeight: "700",
              headingXsFontSize: "12px",
              headingXsFontWeight: "700",
              bodyMdFontSize: "16px",
              bodyMdFontWeight: "400",
              bodySmFontSize: "14px",
              bodySmFontWeight: "400",
              labelMdFontSize: "14px",
              labelMdFontWeight: "400",
              labelMdTextTransform: "none",
              labelSmFontSize: "12px",
              labelSmFontWeight: "400",
            },
          },
        })
      );
    }
  }, [connectState.connectAccount?.stripeID, createSession]);

  return stripeConnectInstance;
};