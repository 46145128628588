import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, Button, Card, Cell, CircularSpinner, Colors, ConfirmModal, Form, HasProductRole, Link, ModalLauncher, OrgRoles, Products, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TextField, View, generateId, useAlertState, useAuthState, useForm } from "@barscience/global-components";

/* Get Join Code Query */
const GET_JOIN_CODE = gql`
query getJoinCode($orgId: ID!) {
  org(id: $orgId) {
    id
    joinCode
  }
}
`;

type GetJoinCodeResponse = {
  org: {
    id: string;
    joinCode: string;
  } | null;
}

/* Invite Employee Mutation */
const INVITE_EMPLOYEE = gql`
mutation inviteUser($email: String!, $firstName: String!, $lastName: String!, $orgId: String) {
  success: inviteUser(email: $email, firstName: $firstName, lastName: $lastName, orgId: $orgId)
}
`;

type InviteEmployeeResponse = {
  success: boolean;
}

type InviteEmployeeInput = {
  employeeEmail: string;
  firstName: string;
  lastName: string;
}

/* Reset Join Code Mutation */
const RESET_JOIN_CODE = gql`
mutation resetJoinCode($orgId: ID!) {
  org: changeJoinCode(orgId: $orgId) {
    id
    joinCode
  }
}
`;

type ResetJoinCodeResponse = {
  org: {
    id: string;
    joinCode: string;
  } | null;
}


export default function AddEmployee() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const { data: joinCodeData, loading: joinCodeIsLoading } = useQuery<GetJoinCodeResponse>(GET_JOIN_CODE, { variables: { orgId: state.user?.org?.id } });
  const [inviteEmployee, { loading: inviteIsLoading, error: inviteError }] = useMutation<InviteEmployeeResponse>(INVITE_EMPLOYEE);
  const [resetJoinCode] = useMutation<ResetJoinCodeResponse>(RESET_JOIN_CODE);

  const handleInviteEmployee = async (values: InviteEmployeeInput) => {
    const { data } = await inviteEmployee({
      variables: {
        email: values.employeeEmail,
        firstName: values.firstName,
        lastName: values.lastName,
        orgId: state.user?.org?.id,
      },
    });

    if (data?.success) {
      const id = generateId();
      const alert = <StandardAlert title='Employee invited' description='They will receive an email invite to create their account' type='success' id={id} />
      addAlert(id, alert, 30);
      inviteForm.resetValues();
    }
  }

  const inviteForm = useForm<InviteEmployeeInput>({
    initialValues: {
      employeeEmail: '',
      firstName: '',
      lastName: '',
    },
    onSubmit: handleInviteEmployee,
  })

  const handleResetJoinCode = async () => {
    await resetJoinCode({ variables: { orgId: state.user?.org?.id } });
  }

  const resetJoinCodeModal = (
    <ConfirmModal title='Reset Join Code?' onConfirm={handleResetJoinCode} confirmLabel='Reset Code'>
      <StyledParagraph>Your org's join code will be permanently changed. The old code will no longer work.</StyledParagraph>
    </ConfirmModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <StyledHeading tag='h3'>Add Employee</StyledHeading>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '24px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
          <Card size='medium' style={{ boxSizing: 'border-box', maxWidth: 'min(500px, 100%)', minWidth: 'min(500px, 100%)' }}>
            <View style={{ gap: '24px' }}>
              <StyledHeading tag='h6'>Invite Employee</StyledHeading>
              <Form handleSubmit={inviteForm.handleSubmit}>
                <View style={{ gap: '24px' }}>
                  <View style={{ gap: '16px' }}>
                    <TextField label={'Employee\'s email'} name='employeeEmail' value={inviteForm.values.employeeEmail} error={inviteForm.errors.employeeEmail} onChange={inviteForm.handleChange} onValidate={inviteForm.handleValidate} required />

                    <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                      <TextField label='First Name' name='firstName' value={inviteForm.values.firstName} error={inviteForm.errors.firstName} onChange={inviteForm.handleChange} onValidate={inviteForm.handleValidate} required />
                      <TextField label='Last Name' name='lastName' value={inviteForm.values.lastName} error={inviteForm.errors.lastName} onChange={inviteForm.handleChange} onValidate={inviteForm.handleValidate} required />
                    </View>
                  </View>

                  <StyledParagraph>If the employee does not already have an account, we'll send them an email invite to create their account. They will then automatically be added to your organization after they create their account.</StyledParagraph>
                  <Button label='Invite Employee' variant='primary' role='button' action={() => { }} type='submit' disabled={inviteForm.hasError} loading={inviteIsLoading} />
                  {inviteError && <StyledParagraph style={{ color: Colors.error500 }}>{inviteError.message.includes('email already exists') ? 'A user with that email already exists. Please have them join your organization using your org\'s join code' : inviteError.message}</StyledParagraph>}
                </View>
              </Form>
            </View>
          </Card>

          <StyledParagraph>OR</StyledParagraph>

          <Card size='medium' style={{ boxSizing: 'border-box', maxWidth: 'min(500px, 100%)', minWidth: 'min(500px, 100%)' }}>
            <View style={{ gap: '24px' }}>
              <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                <StyledHeading tag='h6'>Join Code</StyledHeading>
                <HasProductRole product={Products.Org} roles={[OrgRoles.BillingManager, OrgRoles.Owner]}>
                  <ModalLauncher modal={resetJoinCodeModal}>
                    {({ openModal }) => (
                      <ActionMenu alignment='right'>
                        <ActionItem label='Reset code' onClick={openModal} />
                      </ActionMenu>
                    )}
                  </ModalLauncher>
                </HasProductRole>
              </View>
              {joinCodeIsLoading ?
                <CircularSpinner size='medium' />
                :
                <StyledParagraph style={{ fontSize: '32px', letterSpacing: '2px' }}>{joinCodeData?.org?.joinCode}</StyledParagraph>
              }
              <StyledParagraph>Employees can create their own account from the <Link href='https://barscience.us/signup' target='_blank'>sign up page</Link>, and then join your organization with the code above.</StyledParagraph>
            </View>
          </Card>
        </View>
      </Cell>
    </StandardGrid>
  );
}