import { Cell, Colors, NoPermission, OrgRoles, Products, StandardGrid, StyledHeading, useAuthState, View } from "@barscience/global-components";
import { useStripeConnect } from "../../components/stripeConnect/useStripeConnect";
import { ConnectAccountManagement, ConnectComponentsProvider } from "@stripe/react-connect-js";
import useStripeConnectState from "../../components/stripeConnect/useStripeConnectState";
import NoPaymentAccess from "./NoPaymentAccess";
import { Navigate } from "react-router-dom";

export default function PaymentAccount() {
  const connectInstance = useStripeConnect();
  const { state } = useStripeConnectState();
  const { state: authState } = useAuthState();

  if (!state.connectAccount?.stripeID) {
    return (
      <NoPaymentAccess />
    );
  }

  if (!state.connectAccount.hasCompletedOnboarding) {
    return (
      <Navigate to='/payments/onboarding' />
    );
  }

  if (authState.user?.roles[Products.Org] !== OrgRoles.Owner) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <StyledHeading tag='h3'>Payment Settings</StyledHeading>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ backgroundColor: '#ffffff', border: `1px solid ${Colors.neutral300}`, borderRadius: '4px', padding: '8px 8px 32px 8px' }}>
          {connectInstance && (
            <ConnectComponentsProvider connectInstance={connectInstance}>
              <ConnectAccountManagement />
            </ConnectComponentsProvider>
          )}
        </View>
      </Cell>
    </StandardGrid>
  );
}