import { Cell, Colors, StandardGrid, View } from "@barscience/global-components";
import { useStripeConnect } from "../../components/stripeConnect/useStripeConnect";
import { ConnectAccountOnboarding, ConnectComponentsProvider } from "@stripe/react-connect-js";
import { Navigate } from "react-router-dom";
import useStripeConnectState from "../../components/stripeConnect/useStripeConnectState";
import NoPaymentAccess from "./NoPaymentAccess";
import { useState } from "react";

export default function PaymentOnboarding() {
  const connectInstance = useStripeConnect();
  const { state, refresh } = useStripeConnectState();
  const [isComplete, setIsComplete] = useState(false);

  if (isComplete || state.connectAccount?.hasCompletedOnboarding) {
    return (
      <Navigate to='/payments/dashboard' />
    );
  }

  if (!state.connectAccount?.stripeID) {
    return (
      <NoPaymentAccess />
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ backgroundColor: '#ffffff', border: `1px solid ${Colors.neutral300}`, borderRadius: '4px', padding: '8px 8px 32px 8px' }}>
          {connectInstance && (
            <ConnectComponentsProvider connectInstance={connectInstance}>
              <ConnectAccountOnboarding
                onExit={() => {
                  refresh();
                  setIsComplete(true);
                }}
              />
            </ConnectComponentsProvider>
          )}
        </View>
      </Cell>
    </StandardGrid>
  );
}