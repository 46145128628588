import { ExternalRedirect, HasProductRole, Header, Icons, OrgRoles, Products, Sidebar, SidebarItem, SidebarSection, useAlertState, useAuthState, View } from '@barscience/global-components';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AllEmployees from './pages/employees/AllEmployees';
import EmployeeDetails from './pages/employees/EmployeeDetails';
import AllPaymentMethods from './pages/billing/AllPaymentMethods';
import AddPaymentMethod from './pages/billing/AddPaymentMethod';
import AllInvoices from './pages/billing/AllInvoices';
import CurrentSubscriptions from './pages/billing/CurrentSubscriptions';
import ManageSubscription from './pages/billing/ManageSubscription';
import AddEmployee from './pages/employees/AddEmployee';
import FlagCheck from './components/FlagCheck';
import PaymentDashboard from './pages/payments/PaymentDashboard';
import { gql, useLazyQuery } from '@apollo/client';
import PaymentOnboarding from './pages/payments/PaymentOnboarding';
import PaymentAccount from './pages/payments/PaymentAccount';

const GET_STRIPE_CONNECT_ID = gql`
query getOrgStripeConnectID($orgId: ID!) {
  org(id: $orgId) {
    stripeConnectAccount {
      stripeID
    }
  }
}
`;

type StripeConnectResponse = {
  org: {
    stripeConnectAccount: {
      stripeID: string;
    } | null;
  } | null;
}

function App() {
  const { state } = useAuthState();
  const { alerts } = useAlertState();
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);
  const [getStripeID, { data: stripeConnectData }] = useLazyQuery<StripeConnectResponse>(GET_STRIPE_CONNECT_ID);

  useEffect(() => {
    if (state.user?.org) {
      getStripeID({
        variables: {
          orgId: state.user.org.id,
        },
      });
    }
  }, [state.user, getStripeID]);

  const toggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  }

  if (!state.user) {
    let authUrl = 'https://auth.barscience.us/login';
    if (window.location.href.includes('-dev')) {
      authUrl = 'https://auth-dev.barscience.us/login';
    } else if (!window.location.href.includes('barscience.us')) {
      authUrl = `http://${window.location.hostname}:6002/login`;
    }
    return (
      <ExternalRedirect to={authUrl + '?continue=' + window.location.href} />
    );
  }

  if (!state.user.roles[Products.Org]) {
    return (
      <ExternalRedirect to='https://start.barscience.us' />
    );
  }

  return (
    <>
      {alerts.length > 0 &&
        <View style={{ bottom: '24px', gap: '16px', left: '24px', position: 'fixed', zIndex: 1 }}>
          {alerts.map((a) => {
            return (
              <React.Fragment key={a.id}>
                {a.alert}
              </React.Fragment>
            );
          })}
        </View>
      }
      <View style={{ height: '100vh' }}>
        <Header product='org-admin' onSidebarClick={toggleSidebar} />

        <View style={{ flexDirection: 'row', height: '100%' }}>
          <Sidebar isOpen={sidebarIsOpen}>
            <FlagCheck flagId='stripe_connect_onboarding'>
              {stripeConnectData?.org?.stripeConnectAccount?.stripeID ?
                <SidebarSection label='Payments'>
                  <SidebarItem label='Dashboard' to='/payments/dashboard' icon={Icons.Home} />
                  <HasProductRole product={Products.Org} roles={[OrgRoles.Owner]}>
                    <SidebarItem label='Settings' to='/payments/account' icon={Icons.Admin} />
                  </HasProductRole>
                </SidebarSection>
                :
                <></>
              }
            </FlagCheck>

            <SidebarSection label='Staff'>
              <SidebarItem label='Employees' to='/employees' icon={Icons.User} includeSubpaths />
            </SidebarSection>

            <HasProductRole product={Products.Org} roles={[OrgRoles.BillingManager, OrgRoles.Owner]}>
              <SidebarSection label='Billing'>
                <SidebarItem label='Subscriptions' to='/subscriptions' icon={Icons.AppGrid} />
                <SidebarItem label='Invoices' to='/invoices' icon={Icons.Receipt} />
                <SidebarItem label='Payment Methods' to='/payment-methods' icon={Icons.CreditCard} />
              </SidebarSection>
            </HasProductRole>
          </Sidebar>

          <Routes>
            <Route path='/employees' element={<AllEmployees />} />
            <Route path='/employees/:userId' element={<EmployeeDetails />} />
            <Route path='/employees/add' element={<AddEmployee />} />

            <Route path='/subscriptions' element={<CurrentSubscriptions />} />
            <Route path='/subscriptions/:productId' element={<ManageSubscription />} />

            <Route path='/invoices' element={<AllInvoices />} />

            <Route path='/payment-methods' element={<AllPaymentMethods />} />
            <Route path='/payment-methods/add' element={<AddPaymentMethod />} />

            <Route path='/payments/dashboard' element={<PaymentDashboard />} />
            <Route path='/payments/onboarding' element={<PaymentOnboarding />} />
            <Route path='/payments/account' element={<PaymentAccount />} />

            <Route path='/*' element={<Navigate to='/employees' />} />
          </Routes>
        </View>
      </View>
    </>
  );
}

export default App;
