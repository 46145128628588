import { Cell, Link, StandardGrid, StyledHeading, StyledParagraph, View } from "@barscience/global-components";

export default function NoPaymentAccess() {
  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', gap: '32px', justifyContent: 'center', width: '100%' }}>
          <img src='illustrations/NoPermission.svg' alt='A lock and key' style={{ flexShrink: 1, maxWidth: 'min(400px, 70%)', minWidth: '250px', width: '100%' }} />

          <View style={{ gap: '16px', maxWidth: 'min(800px, 90%)', textAlign: 'center' }}>
            <StyledHeading tag='h3'>Your organization does not have access to payment processing.</StyledHeading>
            <StyledParagraph>Please <Link href='https://support.barscience.us' target='_blank'>contact Bar Science support</Link> to learn how to set up payment processing.</StyledParagraph>
          </View>
        </View>
      </Cell>
    </StandardGrid>
  );
}