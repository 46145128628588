import { gql, useLazyQuery } from "@apollo/client";
import { AtomSpinner, useAuthState } from "@barscience/global-components";
import { css, StyleSheet } from "aphrodite";
import { createContext, Dispatch, Reducer, useEffect, useReducer } from "react";

type StripeConnectContextProps = {
  state: StripeConnectState;
  dispatch: Dispatch<StripeConnectAction>;
  refresh: () => void;
}

type StripeConnectProviderProps = {
  children: React.ReactNode;
}

const GET_CONNECT_DETAILS = gql`
query getOrgStripeConnectDetails($orgId: ID!) {
  org(id: $orgId) {
    stripeConnectAccount {
      stripeID
      hasChargesEnabled
      hasPayoutsEnabled
      hasCompletedOnboarding
      hasMissingRequirements
      isDisabled
      isUnderStripeReview
    }
  }
}
`;

type GetConnectDetailsResponse = {
  org: {
    stripeConnectAccount: {
      stripeID: string;
      hasChargesEnabled: boolean;
      hasPayoutsEnabled: boolean;
      hasCompletedOnboarding: boolean;
      hasMissingRequirements: boolean;
      isDisabled: boolean;
      isUnderStripeReview: boolean;
    } | null;
  } | null;
};

export const StripeConnectContext = createContext<StripeConnectContextProps>({} as StripeConnectContextProps);

export const StripeConnectProvider = ({ children }: StripeConnectProviderProps) => {
  const { state: userState } = useAuthState();
  const [state, dispatch] = useReducer(stripeConnectStateReducer, initialState);
  const [getConnectDetails, { loading }] = useLazyQuery<GetConnectDetailsResponse>(GET_CONNECT_DETAILS);

  useEffect(() => {
    if (userState.user?.org) {
      getConnectDetails({
        variables: {
          orgId: userState.user.org.id,
        },
      }).then(({ data, error }) => {
        if (error) {
          console.log(error);
        }

        if (data?.org?.stripeConnectAccount) {
          dispatch(setStripeConnectAccount(data.org.stripeConnectAccount));
        }
      });
    }
  }, [userState.user?.org, getConnectDetails]);

  const refresh = () => {
    if (userState.user?.org) {
      getConnectDetails({
        variables: {
          orgId: userState.user.org.id,
        },
      }).then(({ data, error }) => {
        if (error) {
          console.log(error);
        }

        if (data?.org?.stripeConnectAccount) {
          dispatch(setStripeConnectAccount(data.org.stripeConnectAccount));
        }
      });
    }
  }

  const styles = StyleSheet.create({
    loadingContainer: {
      alignContent: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      width: '100%',
    },
  });

  if (loading) {
    return (
      <div className={css(styles.loadingContainer)}>
        <AtomSpinner size='large' />
      </div>
    );
  }

  return (
    <StripeConnectContext.Provider value={{ state, dispatch, refresh }}>
      {children}
    </StripeConnectContext.Provider>
  )
}

export type StripeConnectState = {
  connectAccount: StripeConnectAccount | null;
}

type StripeConnectAccount = {
  stripeID: string;
  hasChargesEnabled: boolean;
  hasPayoutsEnabled: boolean;
  hasCompletedOnboarding: boolean;
  hasMissingRequirements: boolean;
  isDisabled: boolean;
  isUnderStripeReview: boolean;
}

export type StripeConnectAction =
  | {
    type: "SET_STRIPE_CONNECT_ACCOUNT"
    payload: StripeConnectAccount | null
  }

export const stripeConnectStateReducer: Reducer<StripeConnectState, StripeConnectAction> = (state: StripeConnectState, action: StripeConnectAction): StripeConnectState => {
  switch (action.type) {
    case "SET_STRIPE_CONNECT_ACCOUNT": {
      return {
        connectAccount: action.payload,
      }
    }
    default:
      return state
  }
}

export const initialState = {
  connectAccount: null,
}

export function setStripeConnectAccount(connectAccount: StripeConnectAccount): StripeConnectAction {
  return {
    type: "SET_STRIPE_CONNECT_ACCOUNT",
    payload: connectAccount,
  }
}