import { gql, useLazyQuery } from "@apollo/client";
import { AtomSpinner, Card, Cell, Colors, ErrorPage, Link, NoPermission, OrgRoles, Products, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, View, useAuthState } from "@barscience/global-components";
import { useEffect } from "react";

/* Get Invoices Query */
const GET_INVOICES = gql`
query getInvoicesForOrg($orgId: ID!, $next: String, $prev: String) {
  invoicesForOrg(orgId: $orgId, next: $next, prev: $prev) {
    id
    amountPaid
    created
    hostedInvoiceUrl
    isPaid
    periodStart
    periodEnd
    productName
    total
  }
}
`;

type GetInvoicesResponse = {
  invoicesForOrg: Invoice[] | null;
}

type Invoice = {
  id: string;
  amountPaid: string;
  created: string;
  hostedInvoiceUrl: string;
  isPaid: boolean;
  periodStart: string;
  periodEnd: string;
  productName: string;
  total: string;
}

/* Page Settings */
const ALLOWED_ROLES = [OrgRoles.Owner, OrgRoles.BillingManager];

export default function AllInvoices() {
  const { state } = useAuthState();
  const [getInvoices, { data: invoiceData, loading: invoicesAreLoading, error: invoiceError }] = useLazyQuery<GetInvoicesResponse>(GET_INVOICES);

  useEffect(() => {
    getInvoices({
      variables: {
        orgId: state.user?.org?.id,
        next: null,
        prev: null
      },
    });
  }, [getInvoices, state.user?.org?.id]);

  if (!state.user?.roles[Products.Org] || !ALLOWED_ROLES.includes(state.user?.roles[Products.Org])) {
    return <NoPermission />;
  }

  if (invoiceError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <StyledHeading tag='h3'>Invoices</StyledHeading>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {invoicesAreLoading ?
          <AtomSpinner size='medium' />
          :
          <Card size='medium'>
            <View>
              {invoiceData?.invoicesForOrg?.length === 0 ?
                <StyledParagraph>No invoices found.</StyledParagraph>
                :
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell>Product</TableHeaderCell>
                      <TableHeaderCell>Billing Period</TableHeaderCell>
                      <TableHeaderCell>Total</TableHeaderCell>
                      <TableHeaderCell>Status</TableHeaderCell>
                      <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {invoiceData?.invoicesForOrg?.map((invoice, index) => (
                      <TableRow key={index}>
                        <TableCell>{invoice.productName}</TableCell>
                        <TableCell style={{ minWidth: 'fit-content', 'text-wrap': 'nowrap' }}>{new Date(invoice.periodStart).toLocaleDateString()} - {new Date(invoice.periodEnd).toLocaleDateString()}</TableCell>
                        <TableCell>{invoice.total}</TableCell>
                        <TableCell>{invoice.isPaid ? <span style={{ color: Colors.primary500 }}>PAID</span> : <span style={{ color: Colors.error500 }}>UNPAID</span>}</TableCell>
                        <TableCell style={{ minWidth: 'fit-content', 'text-wrap': 'nowrap' }}><Link href={invoice.hostedInvoiceUrl}>View Invoice</Link></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              }
            </View>
          </Card>
        }
      </Cell>
    </StandardGrid>
  );
}