import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, Checkbox, Colors, ErrorPage, InfoPanel, Link, NoPermission, NotFound, OptionBar, OptionItem, OrgRoles, Products, Radio, RadioGroup, StandardAlert, StandardGrid, StyledCaption, StyledHeading, StyledParagraph, TextArea, View, generateId, useAlertState, useAuthState, useForm } from "@barscience/global-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";

/* Get Plans Query */
const GET_PLANS = gql`
query getPlansForProductSubscription($productId: ID!) {
  plans(productId: $productId) {
    id
    name
    type
    cost
    costType
    userLimit
    product {
      id
      name
      description
    }
  }
}
`;

type GetPlansResponse = {
  plans: Plan[] | null;
}

type Plan = {
  id: string;
  name: string;
  type: PlanType;
  cost: string;
  costType: CostType;
  userLimit: number;
  product: {
    id: string;
    name: string;
    description: string | null;
  };
}

type CostType = 'FLAT' | 'PER_USER';
type PlanType = 'ANNUAL' | 'MONTHLY';

/* Get Product Query */
const GET_PRODUCT = gql`
query getProductDetailsForSubscription($productId: ID!) {
  product(id: $productId) {
    id
    name
    description
  }
}
`;

type GetProductResponse = {
  product: {
    id: string;
    name: string;
    description: string | null;
  } | null;
}

/* Get Current Plan Subscription */
const GET_CURRENT_PLAN = gql`
query getOrgPlanForProduct($orgId: ID!, $productId: ID!) {
  orgPlanForProduct(orgId: $orgId, productId: $productId) {
    plan {
      id
      name
      isInactive
      isPublic
      cost
      costType
      userLimit
      type
    }
    cancelledAt
    cancelEffectiveDate
  }
}
`;

type GetCurrentPlanResponse = {
  orgPlanForProduct: {
    plan: {
      id: string;
      name: string;
      isInactive: boolean;
      isPublic: boolean;
      cost: string;
      costType: CostType;
      userLimit: number;
      type: PlanType;
    };
    cancelledAt: string | null;
    cancelEffectiveDate: string | null;
  } | null;
}

/* Has Payment Method Query */
const HAS_PAYMENT_METHOD = gql`
query doesOrgHavePaymentMethod($orgId: ID!) {
  doesOrgHavePaymentMethod(orgId: $orgId)
}
`;

type HasPaymentMethodResponse = {
  doesOrgHavePaymentMethod: boolean;
}

/* Create Subscription Mutation */
const CREATE_SUBSCRIPTION = gql`
mutation createSubscription($orgId: ID!, $planId: ID!) {
  createSubscription(orgId: $orgId, planId: $planId) {
    plan {
      id
      name
    }
    nextBillingDate
  }
}
`;

type CreateSubscriptionResponse = {
  createSubscription: {
    plan: {
      id: string;
      name: string;
    };
    nextBillingDate: string | null;
  } | null;
}

type StartTrialInput = {
  agreeToTerms: boolean;
}

/* Cancel Subscription Mutation */
const CANCEL_SUBSCRIPTION = gql`
mutation cancelSubscription($orgId: ID!, $planId: ID!, $cancelReason: CancelReason!, $feedback: String) {
  cancelSubscription(orgId: $orgId, planId: $planId, cancelReason: $cancelReason, feedback: $feedback) {
    cancelEffectiveDate
  }
}
`;

type CancelSubscriptionResponse = {
  cancelSubscription: {
    cancelEffectiveDate: string | null;
  } | null;
}

type CancelSubscriptionInput = {
  reason: string;
  feedback: string;
}

/* Page Settings */
const ALLOWED_ROLES = [OrgRoles.Owner, OrgRoles.BillingManager];
const DEFAULT_TRIAL_DAYS = 14;

export default function ManageSubscription() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const { productId } = useParams();
  const navigate = useNavigate();
  const [planType, setPlanType] = useState<PlanType>('MONTHLY');
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: paymentData, loading: paymentDataIsLoading, error: paymentError } = useQuery<HasPaymentMethodResponse>(HAS_PAYMENT_METHOD, {
    variables: {
      orgId: state.user?.org?.id,
    },
  });
  const { data: planData, loading: plansAreLoading, error: planError } = useQuery<GetPlansResponse>(GET_PLANS, {
    variables: {
      productId: productId,
    },
  });
  const { data: productData, loading: productIsLoading, error: productError } = useQuery<GetProductResponse>(GET_PRODUCT, {
    variables: {
      productId: productId,
    },
  });
  const { data: currentPlanData, loading: currentPlanIsLoading, error: currentPlanError } = useQuery<GetCurrentPlanResponse>(GET_CURRENT_PLAN, {
    variables: {
      orgId: state.user?.org?.id,
      productId: productId,
    },
    fetchPolicy: 'network-only',
  });
  const [createSubscription, { loading: createSubscriptionIsLoading }] = useMutation<CreateSubscriptionResponse>(CREATE_SUBSCRIPTION);
  const [cancelSubscription, { loading: cancelSubscriptionIsLoading }] = useMutation<CancelSubscriptionResponse>(CANCEL_SUBSCRIPTION, {
    refetchQueries: [GET_CURRENT_PLAN],
  });

  const selectedPlanID = searchParams.get('plan');

  useEffect(() => {
    if (planData?.plans && planData.plans.length > 0) {
      setPlanType(planData.plans[0].type);
    } else {
      setPlanType('MONTHLY');
    }
  }, [planData]);

  const handleCreateSubscription = async (values: StartTrialInput) => {
    if (!values.agreeToTerms || !selectedPlanID || !state.user?.org?.id) {
      return;
    }

    const { data, errors } = await createSubscription({
      variables: {
        orgId: state.user?.org?.id,
        planId: selectedPlanID,
      },
    });

    if (data?.createSubscription?.nextBillingDate) {
      navigate('/subscriptions');
    }

    if (errors) {
      const alertId = generateId();
      const alert = (
        <StandardAlert type='error' title='Error starting trial' description={errors[0].message} id={alertId} />
      );

      addAlert(alertId, alert);
    }
  }

  const startTrialForm = useForm<StartTrialInput>({
    initialValues: {
      agreeToTerms: false,
    },
    onSubmit: handleCreateSubscription,
  });

  const handleCancelSubscription = async (values: CancelSubscriptionInput) => {
    if (!selectedPlanID || !state.user?.org?.id) {
      return;
    }

    const { data, errors } = await cancelSubscription({
      variables: {
        orgId: state.user?.org?.id,
        planId: selectedPlanID,
        cancelReason: values.reason,
        feedback: values.feedback,
      },
    });

    if (data?.cancelSubscription?.cancelEffectiveDate) {
      setSearchParams({});
    }

    if (errors) {
      const alertId = generateId();
      const alert = (
        <StandardAlert type='error' title='Error cancelling subscription' description={errors[0].message} id={alertId} />
      );

      addAlert(alertId, alert);
    }
  }

  const cancelSubscriptionForm = useForm<CancelSubscriptionInput>({
    initialValues: {
      reason: '',
      feedback: '',
    },
    onSubmit: handleCancelSubscription,
  });

  if (!state.user?.roles[Products.Org] || !ALLOWED_ROLES.includes(state.user?.roles[Products.Org])) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (planError || productError || currentPlanError || paymentError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  if (plansAreLoading || productIsLoading || currentPlanIsLoading || paymentDataIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!planData?.plans) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  // The user is cancelling their current plan
  if (selectedPlanID && selectedPlanID === currentPlanData?.orgPlanForProduct?.plan.id) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <StyledHeading tag='h3'>Cancel {productData?.product?.name} Plan</StyledHeading>
        </Cell>
        <Cell lg={12} md={8} sm={4}>
          <Card size='medium' style={{ boxSizing: 'border-box', maxWidth: 'min(100%, 600px)', minWidth: 'min(100%, 400px)', width: 'fit-content' }}>
            <View style={{ gap: '24px' }}>
              <StyledHeading tag='h6'>We're sad to see you go</StyledHeading>
              <View>
                <StyledParagraph bold>What happens next?</StyledParagraph>
                <ul style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                  <li><StyledParagraph>Your subscription will be cancelled at the end of the current billing period.</StyledParagraph></li>
                  <li><StyledParagraph>Your users will no longer have access to this product at the end of the billing period.</StyledParagraph></li>
                  <li><StyledParagraph>We will keep your data for 30 days, in case you change your mind. After 30 days, your data will be automatically deleted.</StyledParagraph></li>
                  <li><StyledParagraph>If you choose to subscribe to this product again in the future, the price may be different than what you're paying today.</StyledParagraph></li>
                </ul>
              </View>

              <RadioGroup name='reason' label='Please select your reason for cancelling' value={cancelSubscriptionForm.values.reason} error={cancelSubscriptionForm.errors.reason} onChange={cancelSubscriptionForm.handleChange} required>
                <Radio label={'It\'s too expensive'} value='TOO_EXPENSIVE' />
                <Radio label={'It\'s missing important features'} value='MISSING_FEATURES' />
                <Radio label={'I\'m switching to another service'} value='SWITCHED_SERVICE' />
                <Radio label={'I don\'t use it enough'} value='UNUSED' />
                <Radio label={'Poor customer service'} value='CUSTOMER_SERVICE' />
                <Radio label={'It\'s too complex or difficult to use'} value='TOO_COMPLEX' />
                <Radio label={'It\'s too low quality'} value='LOW_QUALITY' />
                <Radio label='Other' value='OTHER' />
              </RadioGroup>

              <TextArea label='Any additional feedback you would like to share with us?' name='feedback' value={cancelSubscriptionForm.values.feedback} error={cancelSubscriptionForm.errors.feedback} onChange={cancelSubscriptionForm.handleChange} />

              <View style={{ flexDirection: 'row', gap: '24px', justifyContent: 'flex-end', width: '100%', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                <Button label='Nevermind, keep my subscription' variant='tertiary' role='button' action={() => { setSearchParams({}); }} style={{ maxWidth: '', minWidth: 'fit-content', width: 'fit-content' }} />
                <Button label='Cancel Subscription' variant='primary' destructive role='button' action={() => { handleCancelSubscription(cancelSubscriptionForm.values); }} disabled={cancelSubscriptionForm.values.reason === ''} loading={cancelSubscriptionIsLoading} />
              </View>
            </View>
          </Card>
        </Cell>
      </StandardGrid>
    );
  }

  // The user is signing up for a new plan
  if (selectedPlanID && currentPlanData?.orgPlanForProduct?.plan === undefined) {
    if (!paymentData?.doesOrgHavePaymentMethod) {
      return (
        <StandardGrid>
          <Cell lg={12} md={8} sm={4}>
            <View style={{ alignItems: 'center', gap: '16px', height: '60vh', justifyContent: 'center' }}>
              <img src='/illustrations/credit-card.svg' width='30%' alt='Credit card illustration' />
              <StyledHeading tag='h5'>No payment methods found</StyledHeading>
              <StyledParagraph style={{ textAlign: 'center' }}>You must have a payment method linked to your account before subscribing to a product.</StyledParagraph>
              <Button label='Add Payment Method' variant='primary' role='link' href='/payment-methods/add' style={{ maxWidth: '', minWidth: 'fit-content', width: 'fit-content' }} />
            </View>
          </Cell>
        </StandardGrid>
      );
    }


    const selectedPlan = planData.plans.find((plan) => plan.id === selectedPlanID);

    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <StyledHeading tag='h3'>Confirm Your Subscription</StyledHeading>
        </Cell>
        <Cell lg={12} md={8} sm={4}>
          <View>
            <Card size='medium' style={{ boxSizing: 'border-box', maxWidth: 'min(100%, 600px)', minWidth: 'min(100%, 400px)', width: 'fit-content' }}>
              <View style={{ gap: '24px' }}>
                <StyledHeading tag='h6'>Try the {selectedPlan?.name} plan</StyledHeading>
                <StyledParagraph>Start your <span style={{ fontWeight: 600 }}>{DEFAULT_TRIAL_DAYS} day free trial</span> of the {selectedPlan?.product.name} {selectedPlan?.name} plan.</StyledParagraph>
                <InfoPanel type='info'>
                  <View style={{ gap: '16px' }}>
                    <StyledParagraph>We won't charge you for your free trial period. You can cancel your trial at any time from the <Link href='/subscriptions'>Subscriptions</Link> page.</StyledParagraph>
                    <StyledParagraph>You will automatically be billed on a {selectedPlan?.type === 'ANNUAL' ? 'annual' : 'monthly'} basis starting at the end of your trial period.</StyledParagraph>
                  </View>
                </InfoPanel>

                <View style={{ flexDirection: 'row', gap: '8px' }}>
                  <Checkbox name='agreeToTerms' checked={startTrialForm.values.agreeToTerms} onChange={startTrialForm.handleChange} />
                  <StyledParagraph>I agree to the <Link href='https://barscience.us/about/tos'>Bar Science Terms of Service</Link> and acknowledge the <Link href='https://barscience.us/about/privacy-policy'>Privacy Policy</Link>.</StyledParagraph>
                </View>

                <View style={{ flexDirection: 'row', gap: '24px', justifyContent: 'flex-end', width: '100%' }}>
                  <Button label='Cancel' variant='tertiary' role='button' action={() => { setSearchParams({}); }} />
                  <Button label='Start Trial' variant='primary' role='button' action={() => { handleCreateSubscription(startTrialForm.values); }} disabled={!startTrialForm.values.agreeToTerms} loading={createSubscriptionIsLoading} />
                </View>
              </View>
            </Card>
          </View>
        </Cell>
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <StyledHeading tag='h3'>{productData?.product?.name} Plans</StyledHeading>
      </Cell>
      {(planData.plans.some((plan) => plan.type === 'ANNUAL') && planData.plans.some((plan) => plan.type === 'MONTHLY')) && <Cell lg={12} md={8} sm={4}>
        <OptionBar selectedValue={planType} onChange={(value) => { setPlanType(value as PlanType); }}>
          <OptionItem label='Monthly' value='MONTHLY' />
          <OptionItem label='Annual' value='ANNUAL' />
        </OptionBar>
      </Cell>}
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '32px', justifyContent: 'center' }}>
          {(currentPlanData?.orgPlanForProduct && (currentPlanData?.orgPlanForProduct?.plan.isInactive || !currentPlanData?.orgPlanForProduct?.plan.isPublic)) &&
            <Card size='medium' style={{ border: `2px solid ${Colors.primary500}`, boxSizing: 'border-box', maxWidth: 'min(100%, 350px)', minWidth: 'min(100%, 350px)' }}>
              <View style={{ alignItems: 'center', gap: '16px', padding: '8px 24px 24px 24px' }}>
                <StyledCaption style={{ color: Colors.primary500, fontWeight: 'bold', textTransform: 'uppercase' }}>Current Plan</StyledCaption>

                <View style={{ alignItems: 'center', gap: '32px' }}>
                  <StyledHeading tag='h6' style={{ maxWidth: '200px', textAlign: 'center' }}>{currentPlanData?.orgPlanForProduct?.plan.name}</StyledHeading>
                  <StyledParagraph style={{ fontWeight: 600, fontSize: '24px', textAlign: 'center' }}>{currentPlanData?.orgPlanForProduct?.plan.cost}{getCostTypeLabel(currentPlanData?.orgPlanForProduct?.plan.costType || 'FLAT')}{getCycleLabel(currentPlanData?.orgPlanForProduct?.plan.type || 'MONTHLY')}</StyledParagraph>
                  {currentPlanData?.orgPlanForProduct?.plan.userLimit ?
                    <StyledParagraph>Up to {currentPlanData?.orgPlanForProduct?.plan.userLimit} users</StyledParagraph>
                    :
                    <StyledParagraph>Unlimited Users</StyledParagraph>
                  }

                  {(currentPlanData?.orgPlanForProduct?.cancelEffectiveDate !== null ?
                    <StyledParagraph style={{ color: Colors.neutral700, fontStyle: 'italic', textAlign: 'center' }}>Your subscription will be cancelled on {new Date(currentPlanData?.orgPlanForProduct?.cancelEffectiveDate || '').toLocaleDateString()}.</StyledParagraph>
                    :
                    <Button label='Cancel Plan' variant='tertiary' role='button' action={() => { setSearchParams({ plan: currentPlanData?.orgPlanForProduct?.plan.id || '' }); }} />
                  )}
                </View>
              </View>
            </Card>
          }

          {planData.plans.filter((plan) => plan.type === planType).map((plan) => {
            const hasAnyPlan = currentPlanData?.orgPlanForProduct?.plan !== undefined;
            const isCurrentPlan = currentPlanData?.orgPlanForProduct?.plan.id === plan.id;

            return (
              <Card size='medium' key={plan.id} style={{ boxSizing: 'border-box', maxWidth: 'min(100%, 350px)', minWidth: 'min(100%, 350px)', ...(isCurrentPlan ? { border: `2px solid ${Colors.primary500}` } : {}) }}>
                <View style={{ alignItems: 'center', gap: '16px', padding: '8px 24px 24px 24px' }}>
                  {isCurrentPlan && <StyledCaption style={{ color: Colors.primary500, fontWeight: 'bold', textTransform: 'uppercase' }}>Current Plan</StyledCaption>}

                  <View style={{ alignItems: 'center', gap: '32px' }}>
                    <StyledHeading tag='h6' style={{ maxWidth: '200px', textAlign: 'center' }}>{plan.name}</StyledHeading>
                    <StyledParagraph style={{ fontWeight: 600, fontSize: '24px', textAlign: 'center' }}>{plan.cost}{getCostTypeLabel(plan.costType)}{getCycleLabel(plan.type)}</StyledParagraph>
                    {plan.userLimit ?
                      <StyledParagraph>Up to {plan.userLimit} users</StyledParagraph>
                      :
                      <StyledParagraph>Unlimited Users</StyledParagraph>
                    }

                    {isCurrentPlan ?
                      (currentPlanData?.orgPlanForProduct?.cancelEffectiveDate !== null ?
                        <StyledParagraph style={{ color: Colors.neutral700, fontStyle: 'italic', textAlign: 'center' }}>Your subscription will be cancelled on {new Date(currentPlanData.orgPlanForProduct?.cancelEffectiveDate || '').toLocaleDateString()}.</StyledParagraph>
                        :
                        <Button label='Cancel Plan' variant='tertiary' role='button' action={() => { setSearchParams({ plan: plan.id }); }} />
                      )
                      :
                      hasAnyPlan ?
                        <View>
                          <StyledParagraph style={{ textAlign: 'center' }}>Want to change plans? Please <Link href='https://support.barscience.us'>contact our support team</Link>!</StyledParagraph>
                        </View>
                        :
                        <Button label='Start Free Trial' variant='primary' role='button' action={() => { setSearchParams({ plan: plan.id }); }} />
                    }
                  </View>
                </View>
              </Card>
            );
          })}
        </View>
      </Cell>
    </StandardGrid>
  );
}

const getCostTypeLabel = (type: CostType) => {
  switch (type) {
    case 'FLAT':
      return '';
    case 'PER_USER':
      return ' / user';
    default:
      return '';
  }
}

const getCycleLabel = (type: PlanType) => {
  switch (type) {
    case 'ANNUAL':
      return ' / year';
    case 'MONTHLY':
      return ' / month';
    default:
      return '';
  }
}